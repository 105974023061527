<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ppmpModal"
      scrollable
    >
      <v-card>
        <v-toolbar v-if="data.BudgetID" max-height="65" dark color="green darken-2">
          <v-toolbar-title
            >{{ data.BudgetCode }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <span style="width: 250px">
            Total <span class="hidden-sm-and-down">Balance </span> : ₱ {{ formatPrice(data.Amount) }} <br />
            Available <span class="hidden-sm-and-down">Balance </span> : ₱
            {{
              formatPrice(
                parseFloat(data.Amount) - parseFloat((parseFloat(amount_dbm) + parseFloat(amount_non_dbm) + parseFloat(amount_lib)).toFixed(2))
              )
            }}
          </span>
          <v-btn icon dark @click="clickCloseMainDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 900px;">
        <v-form @submit.prevent ref="Formref3">
        <v-row class="mt-5">
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formData.PPMPDescription"
              label="PPMP Description"
              color="green darken-2"
              :rules="[formRules.required]"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
                      v-model="formData.approvalID"
                      color="green"
                      item-text="FirstName"
                      item-value="UserID"
                      :rules="[formRules.required]"
                      :items="approvalList"
                      dense
                      outlined
                      label="Recommending Approval">

                 <template v-slot:selection="data">
                 {{data.item.FirstName}} {{data.item.MiddleName}} {{data.item.LastName}}, {{data.item.EducQuali}} 
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{data.item.FirstName}} {{data.item.MiddleName}} {{data.item.LastName}}, {{data.item.EducQuali}} </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
          </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" align="end" v-if="currentData.PPMPStatusID != 12">
            <v-btn
            class="white--text mr-2"
            color="green darken-2"
            @click="savePPMPs()"
            :loading="saveLoading"
            :disabled="isNegative(parseFloat((parseFloat(data.Amount) - (parseFloat(amount_dbm) + parseFloat(amount_non_dbm) + parseFloat(amount_lib))).toFixed(2)))"
            outlined
          >
            <v-icon class="mr-1">mdi-check-circle-outline</v-icon>
            Save
          </v-btn>
            <v-btn
            class="white--text"
            color="blue darken-2"
            :disabled="parseFloat((parseFloat(data.Amount) - (parseFloat(amount_dbm) + parseFloat(amount_non_dbm) + parseFloat(amount_lib))).toFixed(2)) > 0 || isNegative(parseFloat((parseFloat(data.Amount) - (parseFloat(amount_dbm) + parseFloat(amount_non_dbm) + parseFloat(amount_lib))).toFixed(2)))"
            @click="publish()"
          >
            <v-icon class="mr-2">mdi-check-outline</v-icon>
            Publish
          </v-btn>
          </v-col>
        </v-row>
        </v-form>
        <v-divider class="hidden-sm-and-up my-5"></v-divider>
        <v-row>
          <v-col cols="12" md="7">
          <v-tabs
            v-model="tab"
            color="header"
          >
            <v-tabs-slider color="header"></v-tabs-slider>

            <v-tab
              v-for="item in tab_items"
              :key="item"
              color="header"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
          </v-col>
          <v-col cols="12">
            <v-card class="rounded-lg mb-5">
              <v-data-table
                :loading="loading"
                :headers="headers"
                :items="itemList"
                :options.sync="options"
                :server-items-length="itemList.length"
                hide-default-footer
                class="dt-container"
              >
                <template v-slot:top>
                  <v-toolbar flat height="65">
                    <span class="green--text text--darken-2"
                      >PPMP Item List ({{tab_items[tab]}})</span
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      class="white--text mr-2"
                      color="green"
                      v-if="currentData.PPMPStatusID != 12"
                      :disabled="parseFloat(data.Amount) - (parseFloat(amount_dbm) + parseFloat(amount_non_dbm) + parseFloat(amount_lib)) <= 0"
                      @click="addNewItem()"
                    >
                      <v-icon>mdi-plus</v-icon>
                      Add Item
                    </v-btn>
                     <!-- <span v-if="tab==0">
                      Total (DBM): ₱ {{ formatPrice(amount_dbm) }}
                    </span>
                    <span v-if="tab==1">
                      Total (Non-DBM): ₱ {{ formatPrice(amount_non_dbm) }}
                    </span>
                    <span v-if="tab==2">
                      Total (LIB): ₱ {{ formatPrice(amount_lib) }}
                    </span> -->
                    <v-text-field
                     v-if="tab == 0"
                    :value="'₱ '+ formatPrice(amount_dbm)"
                    label="Amount(DBM)"
                    color="green darken-2"
                    style="max-width: 250px"
                    readonly
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                  <v-text-field
                   v-if="tab == 1"
                    :value="'₱ '+ formatPrice(amount_non_dbm)"
                    label="Amount(NON-DBM)"
                    color="green darken-2"
                    style="max-width: 250px"
                    outlined
                    readonly
                    dense
                    hide-details
                  ></v-text-field>
                  <v-text-field
                    v-if="tab == 2"
                    :value="'₱ '+ formatPrice(amount_lib)"
                    label="Amount(LIB)"
                    color="green darken-2"
                    style="max-width: 250px"
                    outlined
                    readonly
                    dense
                    hide-details
                  ></v-text-field>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                  <span v-if="tab != 2">{{ item.Qty }}</span>
                </template>
                <template v-slot:[`item.UniMeasureDescription`]="{ item }">
                  <span v-if="tab != 2">{{ item.UnitMeasureDescription }}</span>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap" v-if="tab != 2">₱ {{ formatPrice(item.UnitCost) }}</span>
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">
                    ₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}
                  </span>
                </template>
                <template v-slot:[`item.obligation`]="{ item }">
                  <span class="text-no-wrap">
                    ₱ {{formatPrice(parseFloat(item.obligation))}}
                  </span>
                </template>
                <template v-slot:[`item.Actions`]="{ item }">
                  <span class="text-no-wrap">
                  <v-icon
                    small
                    color="green"
                    class="mr-2"
                    v-if="(currentData.PPMPStatusID == 11 && (item.PRID == 0 || parseFloat(item.obligation) < parseFloat(item.Amount))) || typeof item.ppmpItemID == 'undefined'"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    color="green"
                    class="mr-2"
                    disabled
                    v-else
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    color="red"
                    class="mr-2"
                    v-if="(currentData.PPMPStatusID == 11  && item.PRID == 0 && parseFloat(item.obligation) == 0) || typeof item.ppmpItemID == 'undefined'"
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                  <v-icon
                    small
                    color="red"
                    class="mr-2"
                    v-else
                    disabled
                  >
                    mdi-delete
                  </v-icon>
                  </span>
                </template>
              </v-data-table>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="itemListToDelete.length > 0">
            <v-card class="rounded-lg mb-5">
              <v-data-table
                :loading="loading"
                :headers="headers"
                :items="itemListToDelete"
                :options.sync="options"
                :server-items-length="itemListToDelete.length"
                hide-default-footer
                class="dt-container"
              >
              <template v-slot:top>
                  <v-toolbar flat>
                    <span class="red--text text--darken-2"
                      >Deleted Items ({{tab_items[tab]}})</span
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                    color="red"
                    class="white--text"
                    @click="restoreAll()"
                    v-if="itemListToDelete.length > 1"
                    :disabled="disableRestoreAll()"
                  >
                  <v-icon>mdi-delete-restore</v-icon>
                    Restore All
                  </v-btn>
                  </v-toolbar>
              </template>
                <template v-slot:[`item.Qty`]="{ item }">
                  <span v-if="tab != 2">{{ item.Qty }}</span>
                </template>
                <template v-slot:[`item.UniMeasureDescription`]="{ item }">
                  <span v-if="tab != 2">{{ item.UnitMeasureDescription }}</span>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap" v-if="tab != 2">₱ {{ formatPrice(item.UnitCost) }}</span>
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">
                    ₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}
                  </span>
                </template>
                <template v-slot:[`item.Actions`]="{ item }">
                  <v-btn
                    small
                    color="red"
                    class="white--text"
                    @click="restore(item)"
                    :disabled="parseFloat(item.UnitCost) * parseFloat(item.Qty) > parseFloat(data.Amount) - (parseFloat(amount_dbm) + parseFloat(amount_non_dbm) + parseFloat(amount_lib))"
                  >
                  <v-icon>mdi-delete-restore</v-icon>
                    Restore
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  
      <v-dialog v-model="addToPPMP" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1 font-weight-bold" v-if="editItemId"
            >Update Item</span
          >
          <span class="subtitle-1 font-weight-bold" v-else>Add Item</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent ref="Formref">
              <v-row>
                <!-- <v-col cols="12">
                  <v-alert
                    v-model="alertDialog"
                    type="info"
                    outlined
                    class="bg mt-5"
                  >
                    <v-row align="center" class="text-caption">
                      <v-col class="grow">
                        <span>
                          <b>Note: </b>Item prices had increased to 15%.
                          </span>
                        </v-col>
                      <v-col class="shrink">
                        <v-icon @click="alertDialog = false" color="info">mdi-close</v-icon>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col> -->
                <v-col cols="12" sm="12" md="12">
                  <span v-if="editItemId">
                    <v-list-item-content>
                      <v-list-item-title
                        ><b>{{ formData.ItemName }}</b></v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        formData.itemDescription
                      }}</v-list-item-subtitle>
                      <span
                        >₱ {{ formatPrice(formData.UnitCost) }} /
                        {{ formData.UnitMeasureDescription }}</span
                      >
                    </v-list-item-content>
                    <v-divider></v-divider>
                    <br />
                  </span>
                  <v-autocomplete
                    v-else
                    v-model="formData.ItemID"
                    no-filter
                    color="green"
                    item-text="ItemName"
                    item-value="ItemID"
                    :rules="[formRules.required]"
                    :items="items"
                    :loading="itemsisLoading"
                    :search-input.sync="search"
                    dense
                    label="Item"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Search for an Item
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.item.ItemName
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            data.item.ItemDescription
                          }}</v-list-item-subtitle>
                          <span
                            >₱ {{ formatPrice(data.item.UnitCost) }} /
                            {{ data.item.UnitMeasureDescription }}</span
                          >
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.item.ItemName
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            data.item.ItemDescription
                          }}</v-list-item-subtitle>
                          <span
                            >₱ {{ formatPrice(data.item.UnitCost) }} /
                            {{ data.item.UnitMeasureDescription }}</span
                          >
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    outlined
                    dense
                    v-model="formData.Qty"
                    color="green"
                    style="width: 190px"
                    :label="tab == 2 ? 'Amount' : 'Quantity'"
                    :rules="[checkQtyandBalance(), formRules.noNegativeNumberAllowed, formRules.numberRequired, parseFloat(formData.Qty) >= parseFloat(editData.obligation || 0)]"
                    class="pa-0 mt-4"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <span v-if="parseFloat(editData.obligation) > 0">
              <b>Obligation:</b>
              <span :style="parseFloat(formData.Qty) >= editData.obligation ? 'color: green' : 'color: red'">
               ₱ {{formatPrice(parseFloat(editData.obligation))}}
              </span>
          </span>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="cancel()"> Cancel </v-btn>
          <v-btn
            v-if="!editItemId"
            :loading="loadingBtn"
            color="green darken-2"
            text
            @click="addItemToPPMP()"
          >
            Add Item
          </v-btn>
          <v-btn
            v-else
            color="blue darken-1"
            text
            @click="update()"
            :loading="loadingBtn"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="selectSchedDialog" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span class="">SCHEDULE/ MILESTONE OF ACTIVITIES </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent ref="Formref2">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    color="green"
                    label="Select Month"
                    v-model="formData.DateSchedules"
                    :rules="[formRules.required]"
                    :items="filteredMonths"
                    item-value="month"
                    item-text="description"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="selectSchedDialog = false">
            Cancel
          </v-btn>
          <v-btn
            :loading="loadingBtn"
            color="green darken-2"
            text
            @click="SaveDate()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


      <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline mt-5">Confirmation</v-card-title>
            <div class="mx-6">
              <strong>Are you sure you want to delete this item?</strong> <br />
              <b>Item Name: </b> {{itemToDelete.ItemName}}
            </div>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="dialogDelete = false">Cancel</v-btn>
              <v-btn color="red darken-2 white--text" @click="confirmDeleteItem()"><v-icon>mdi-delete</v-icon> Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      
        <v-dialog v-model="dialogActionConfirm" max-width="500px">
          <v-card>
            <v-card-title class="headline mt-5">Confirmation</v-card-title>
            <div class="mx-6">
              <strong>Are you sure you want {{action}} these PPMPs?</strong> <br />
            </div>
            <div class="mx-6 mt-3" v-if="itemListToDelete_dbm.length > 0">
            <b>Deleted Items (DBM): </b> 
              <ul>
                <li v-for="(item, index) in itemListToDelete_dbm" :key="index">
                  {{ item.ItemName}}
                </li>
              </ul>
            </div>
            <div class="mx-6 mt-3" v-if="itemListToDelete_non_dbm.length > 0">
            <b>Deleted Items (NON-DBM): </b> 
              <ul>
                <li v-for="(item, index) in itemListToDelete_non_dbm" :key="index">
                  {{ item.ItemName}}
                </li>
              </ul>
            </div>
            <div class="mx-6 mt-3" v-if="itemListToDelete_lib.length > 0">
            <b>Deleted Items (LIB): </b> 
              <ul>
                <li v-for="(item, index) in itemListToDelete_lib" :key="index">
                  {{ item.ItemName}}
                </li>
              </ul>
            </div>
            <div class="mx-6 mt-3" v-if="itemListToDelete_dbm.length > 0 || itemListToDelete_non_dbm.length > 0 || itemListToDelete_lib.length > 0">
            <i><strong>Note: </strong>All deleted items will be permanently removed.</i>
            </div>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="dialogActionConfirm = false">Cancel</v-btn>
              <v-btn color="green darken-2 white--text" v-if="action == 'save'" class="pr-4" @click="confirmSave()" :loading="saveLoading"><v-icon>mdi-check</v-icon> Save</v-btn>
              <v-btn color="blue darken-2 white--text" v-if="action == 'publish'" class="pr-4" @click="confirmPublish()" :loading="publishLoading"><v-icon>mdi-check</v-icon> Publish</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  props: {
    data: {},
  },
  data: () => ({
    approvalList: [],
    itemsisLoading: false,
    items: [],
    search: null,
    budgetUsedAmount: null,
    headers: [],
    header1: [
      {
        text: "Schedule",
        align: "start",
        sortable: false,
        value: "DateSchedules",
        class: "green darken-2 white--text text-caption font-weight-bold"
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
        class: "green darken-2 white--text text-caption font-weight-bold"
      },
      { text: "Description", value: "ItemDescription", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      { text: "Quantity", value: "Qty", align: "center", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      { text: "Unit Meas.", value: "UnitMeasureDescription", align: "center", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      { text: "Unit Cost", value: "UnitCost", align: "end", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      { text: "Amount", value: "Amount", align: "end", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      { text: "Action", value: "Actions", align: "center", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
    ],
    header2: [
      {
        text: "Schedule",
        align: "start",
        sortable: false,
        value: "DateSchedules",
        class: "green darken-2 white--text text-caption font-weight-bold"
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
        class: "green darken-2 white--text text-caption font-weight-bold"
      },
      { text: "Description", value: "ItemDescription", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      { text: "Amount", value: "Amount", align: "end", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      { text: "Obligation", value: "obligation", align: "end", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      { text: "Action", value: "Actions", align: "center", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
    ],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    filteredMonths: [],
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    addToPPMP: false,
    loading: false,
    ppmp: [],
    dialog: false,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    options: {},
    isLoading: false,
    saveLoading: false,
    publishLoading: false,
    itemList: [],
    formData: {
      Qty: null,
      DateSchedules: null
    },
    loadingBtn: false,
    editItemId: null,
    editItemQty: null,
    tab_items: ['DBM','NON-DBM','LIB'],
    tab: 0,
    selectSchedDialog: false,
    amount_dbm: 0.00,
    amount_non_dbm: 0.00,
    amount_lib: 0.00,
    itemList_dbm: [],
    itemList_non_dbm: [],
    itemList_lib: [],
    current_amount: 0,
    amountUsed: 0,
    dbm_data: [],
    non_dbm_data: [],
    lib_data: [],
    dialogDelete: false,
    itemToDelete: [],
    itemListToDelete: [],
    itemListToDelete_dbm: [],
    itemListToDelete_non_dbm: [],
    itemListToDelete_lib: [],
    editData: {
      obligation: 0,
    },
    dialogActionConfirm: false,
    currentData: [],
    action: null,
  }),
  watch: {
    search(val) {
      this.itemsisLoading = true;
      setTimeout(() => {
        if (val == this.search) {
          this.SearchItem();
        }
      }, 2000);
    },
    options: {
      handler() {
        if (this.totalCount > 0) {
          this.getItems();
        }
      },
      deep: true,
    },
    formData: {
      handler() {
      },
      deep: true,
    },
    data: {
      handler(data) {
        if (data.BudgetID) {
          this.tab = 0;
          this.headers = this.header1;
          this.getApprovalList();
          this.getPPMPItems();
          this.getFilteredMonths(data.BudgetDate, data.BudgetDateEnd)
          this.dialog = true;
        }
      },
      deep: true,
    },
    tab: {
      handler(data) {
        this.currentData = [];
        this.items = [];
        if(data == 0){
          this.headers = this.header1;
          this.itemList = this.itemList_dbm;
          this.itemListToDelete = this.itemListToDelete_dbm;
          if(this.dbm_data){
            this.currentData = this.dbm_data;
          }
        }
        else if(data == 1){
          this.headers = this.header1;
          this.itemList = this.itemList_non_dbm;
          this.itemListToDelete = this.itemListToDelete_non_dbm;
          if(this.non_dbm_data){
          this.currentData = this.non_dbm_data;
          }
        }
        else if(data == 2){
          this.headers = this.header2;
          this.itemList = this.itemList_lib;
          this.itemListToDelete = this.itemListToDelete_lib;
          if(this.lib_data){
          this.currentData = this.lib_data;
          }
        }
      },
      deep: true,
    },
    itemList: {
      handler() {
          if(this.tab == 0){
          let amount = 0;
          this.itemList_dbm.forEach(item => {
            amount += parseFloat(item.Qty) * parseFloat(item.UnitCost);
          });
          this.amount_dbm = amount.toFixed(2);
        }
        else if(this.tab == 1){
          let amount = 0;
          this.itemList_non_dbm.forEach(item => {
            amount += parseFloat(item.Qty) * parseFloat(item.UnitCost);
          });
          this.amount_non_dbm = amount.toFixed(2);
        }
        else if(this.tab == 2){
          let amount = 0;
          this.itemList_lib.forEach(item => {
            amount += parseFloat(item.Qty) * parseFloat(item.UnitCost);
          });
          this.amount_lib = amount.toFixed(2);
        }
      },
      deep: true,
    },
  },
  computed: {

  },
  methods: {
    disableRestoreAll(){
        let amount = 0;
        this.itemListToDelete.forEach(item => {
          amount += parseFloat(item.Qty) * parseFloat(item.UnitCost);
        });
        let available_balance = parseFloat(this.data.Amount) - (parseFloat(this.amount_dbm) + parseFloat(this.amount_non_dbm) + parseFloat(this.amount_lib));
        return amount > available_balance;
      },
    editItem(data) {
        this.editItemQty = data.Qty;
        this.formData.DateSchedules = data.DateSchedules;
        this.editItemId = data.ItemID;
        this.formData.Qty = data.Qty;
        this.formData.ItemName = data.ItemName;
        this.formData.UnitCost = data.UnitCost;
        this.formData.UnitMeasureDescription = data.UnitMeasureDescription;
        this.formData.itemDescription = data.ItemDescription;
        this.formData.ItemID = data.ItemID;
        this.editData = data;
        this.selectSchedDialog = true;
    },
    deleteItem(item) {
      this.itemToDelete = item;
      this.dialogDelete = true;
    },
    confirmDeleteItem(){
      if(this.tab == 0){
        let index = this.itemList_dbm.indexOf(this.itemToDelete);
        if (index > -1) {
          this.itemList_dbm.splice(index, 1);
          this.itemListToDelete_dbm.push(this.itemToDelete);
          this.itemListToDelete = this.itemListToDelete_dbm;
        }
      }
      else if(this.tab == 1){
        let index = this.itemList_non_dbm.indexOf(this.itemToDelete);
        if (index > -1) {
          this.itemList_non_dbm.splice(index, 1);
          this.itemListToDelete_non_dbm.push(this.itemToDelete);
          this.itemListToDelete = this.itemListToDelete_non_dbm;
        }
      }
      else if(this.tab == 2){
        let index = this.itemList_lib.indexOf(this.itemToDelete);
        if (index > -1) {
          this.itemList_lib.splice(index, 1);
          this.itemListToDelete_lib.push(this.itemToDelete);
          this.itemListToDelete = this.itemListToDelete_lib;
        }
      }
      this.dialogDelete = false;
      this.fadeAwayMessage.show = true;
      this.fadeAwayMessage.type = "success";
      this.fadeAwayMessage.message = "Item Successfully Added to the trash!";
      this.fadeAwayMessage.header = "System Message";
    },
    restore(item){
      if(this.tab == 0){
        let index = this.itemListToDelete_dbm.indexOf(item);
        if (index > -1) {
          this.itemListToDelete_dbm.splice(index, 1);
          this.itemList_dbm.push(item);
          this.itemList = this.itemList_dbm;
        }
      }
      else if(this.tab == 1){
        let index = this.itemListToDelete_non_dbm.indexOf(item);
        if (index > -1) {
          this.itemListToDelete_non_dbm.splice(index, 1);
          this.itemList_non_dbm.push(item);
          this.itemList = this.itemList_non_dbm;
        }
      }
      else if(this.tab == 2){
        let index = this.itemListToDelete_lib.indexOf(item);
        if (index > -1) {
          this.itemListToDelete_lib.splice(index, 1);
          this.itemList_lib.push(item);
          this.itemList = this.itemList_lib;
        }
      }
      this.fadeAwayMessage.show = true;
      this.fadeAwayMessage.type = "success";
      this.fadeAwayMessage.message = "Item has successfully restored!";
      this.fadeAwayMessage.header = "System Message";
    },
    update() {
      if (this.$refs.Formref.validate()) {
        this.loadingBtn = true;
        if(this.tab == 0){
        let index = this.itemList_dbm.indexOf(this.editData);
        if (index > -1) {
          this.itemList_dbm[index].Qty = this.formData.Qty;
          this.itemList_dbm[index].DateSchedules = this.formData.DateSchedules;
        }
      }
      else if(this.tab == 1){
        let index = this.itemList_non_dbm.indexOf(this.editData);
        if (index > -1) {
          this.itemList_non_dbm[index].Qty = this.formData.Qty;
          this.itemList_non_dbm[index].DateSchedules = this.formData.DateSchedules;
        }
      }
      else if(this.tab == 2){
        let index = this.itemList_lib.indexOf(this.editData);
        if (index > -1) {
          this.itemList_lib[index].Qty = this.formData.Qty;
          this.itemList_lib[index].DateSchedules = this.formData.DateSchedules;
        }
      }
      this.$refs.Formref.reset();
      this.$refs.Formref2.reset();
      this.editData = {
        obligation: 0,
      };
      this.editItemQty = null;
      this.editItemId = null;
      this.formData.DateSchedules = null;
      this.formData.Qty = null;
      this.formData.ItemName = null;
      this.formData.UnitCost = null;
      this.formData.UnitMeasureDescription = null;
      this.formData.itemDescription = null;
      this.formData.ItemID = null;
      this.addToPPMP = false;
      this.loadingBtn = false;
      }
    },
    checkQtyandBalance() {
      let balance = parseFloat(this.data.Amount) - (parseFloat(this.amount_dbm) + parseFloat(this.amount_non_dbm) + parseFloat(this.amount_lib));
      let itemID = this.formData.ItemID;
      let qty = this.formData.Qty;
      if (!itemID) {
        return true;
      }
      let itemAmount = 0;
      if (this.editItemId) {
        itemAmount =
          (parseFloat(this.formData.UnitCost) * parseFloat(this.formData.Qty)) -
          (parseFloat(this.formData.UnitCost) * parseFloat(this.editItemQty));
      } else {
        this.items.forEach((element) => {
          if (element.ItemID == itemID) {
            itemAmount = element.UnitCost * qty;
          }
        });
      }
      if (balance && itemAmount) {
        balance = balance.toFixed(2);
        itemAmount = itemAmount.toFixed(2);
      }
      return (
        parseFloat(balance) >= parseFloat(itemAmount) || "Insufficient Balance"
      );
    },
    // checkObligation(){
    //   let obligation = parseFloat(this.editData.obligation) || 0;
    //   // console.log(parseFloat(this.formData.Qty), obligation)
    //   // let amount = parseFloat(this.editData.Qty) * parseFloat(this.editData.UnitCost);
    //   return (
    //     (parseFloat(this.formData.Qty) >= obligation && parseFloat(this.formData.Qty) >= obligation) || "Amount must be greater than the obligation"
    //   );
    // },
    clickCloseMainDialog() {
      this.formData = [];
      this.totalCount = 0;
      this.items = [];
      this.itemList = [];
      this.itemList_dbm = [];
      this.itemList_non_dbm = [];
      this.itemList_lib = [];
      this.itemListToDelete_dbm = [];
      this.itemListToDelete_non_dbm = [];
      this.itemListToDelete_lib = [];
      this.amount_dbm = 0.00;
      this.amount_non_dbm = 0.00; 
      this.amount_lib = 0.00;
      this.dialog = false;
      this.eventHub.$emit("closeCreateNewPPMPModal", false);
    },
    getApprovalList() {
    this.axiosCall(
            '/get/approval/List',
            'GET'
        )
        .then((res) => {
          this.approvalList = res.data.data;
        })
		},
    addNewItem(){
      
      this.selectSchedDialog = true;
    },
    SaveDate() {
      if(this.$refs.Formref2.validate()){
        if (this.formData.DateSchedules) {
        this.selectSchedDialog = false;
        this.addToPPMP = true;
      }
      }
    },
      cancel() {
      this.editItemQty = null;
      this.editData = {
        obligation: 0
      };
      (this.editItemId = null), this.$refs.Formref.reset();
      this.addToPPMP = false;
    },
    SearchItem() {
      let data = new FormData();
      data.append("search", this.search);
      data.append("CategoryTypeID", parseInt(this.tab) + 1);
      this.axiosCall("/search/new/item", "POST", data).then((res) => {
        this.items = res.data.data.result;
        this.itemsisLoading = false;
        this.$forceUpdate();
      });
    },
    addItemToPPMP(){
      if (this.$refs.Formref.validate()) {
        let UnitCost = 0;
        let ItemName = '';
        let ItemDescription = '';
        let UnitMeasureDescription = '';
        let CategoryID = '';
      this.items.forEach((element) => {
          if (element.ItemID == this.formData.ItemID) {
            UnitCost = element.UnitCost;
            ItemName = element.ItemName;
            ItemDescription = element.ItemDescription;
            UnitMeasureDescription = element.UnitMeasureDescription;
            CategoryID = element.CategoryID;
          }
        });
        if(this.tab == 0){
          let isDuplicate = false;
          this.itemList_dbm.forEach(item => {
            if(item.ItemID == this.formData.ItemID && item.DateSchedules == this.formData.DateSchedules){
              item.Qty = parseFloat(this.formData.Qty) + parseFloat(item.Qty)
              isDuplicate = true;
            }
          });
        if(!isDuplicate){
          this.itemList_dbm.push({
            "ItemName": ItemName,
            "ItemDescription": ItemDescription,
            "Qty": this.formData.Qty,
            "UnitMeasureDescription": UnitMeasureDescription,
            "ppmpID": this.data.PPMPID,
            "ItemID": this.formData.ItemID,
            "CategoryID": CategoryID,
            "UnitCost": UnitCost,
            "DateSchedules": this.formData.DateSchedules,
            "PRID": 0,
            "obligation": 0,
          });
        }
        this.itemList = this.itemList_dbm;
      }
      else if(this.tab == 1){
      let isDuplicate = false;
        this.itemList_non_dbm.forEach(item => {
          if(item.ItemID == this.formData.ItemID && item.DateSchedules == this.formData.DateSchedules){
            item.Qty = parseFloat(this.formData.Qty) + parseFloat(item.Qty)
            isDuplicate = true;
          }
        });
      if(!isDuplicate){
        this.itemList_non_dbm.push({
              "ItemName": ItemName,
              "ItemDescription": ItemDescription,
              "Qty": this.formData.Qty,
              "UnitMeasureDescription": UnitMeasureDescription,
              "ppmpID": this.data.PPMPID,
              "ItemID": this.formData.ItemID,
              "CategoryID": CategoryID,
              "UnitCost": UnitCost,
              "DateSchedules": this.formData.DateSchedules,
              "PRID": 0,
              "obligation": 0,
            });
      }
      this.itemList = this.itemList_non_dbm;
      }
      else if(this.tab == 2){
      let isDuplicate = false;
      this.itemList_lib.forEach(item => {
        if(item.ItemID == this.formData.ItemID && item.DateSchedules == this.formData.DateSchedules){
          item.Qty = parseFloat(this.formData.Qty) + parseFloat(item.Qty)
          isDuplicate = true;
        }
      });
      if(!isDuplicate){
        this.itemList_lib.push({
          "ItemName": ItemName,
          "ItemDescription": ItemDescription,
          "Qty": this.formData.Qty,
          "UnitCost": UnitCost,
          "ppmpID": this.data.PPMPID,
          "ItemID": this.formData.ItemID,
          "CategoryID": CategoryID,
          "DateSchedules": this.formData.DateSchedules,
          "PRID": 0,
          "obligation": 0,
        });
      }
      this.itemList = this.itemList_lib;
      }
      this.items = [];
      this.$refs.Formref.reset();
      this.$refs.Formref2.reset();
      this.addToPPMP = false;
      }
    },
    savePPMPs(){
      this.action = "save";
      this.dialogActionConfirm = true;
    },
    confirmSave(){
      if (this.$refs.Formref3.validate()) {
        this.saveLoading = true;
        this.tab_items.forEach((desc, tab) => {
        let data = new FormData();
        data.append('BudgetID', this.data.BudgetID);
        data.append('PPMPDescription', this.formData.PPMPDescription);
        data.append('approvalID', this.formData.approvalID);
        let list = [];
        console.log(desc);
        if(tab == 0){
          list = JSON.stringify(this.itemList_dbm);
          data.append('Amount', this.amount_dbm);
        }
        else if(tab == 1){
          list = JSON.stringify(this.itemList_non_dbm);
          data.append('Amount', this.amount_non_dbm);
        }
        else if(tab == 2){
          list = JSON.stringify(this.itemList_lib);
          data.append('Amount', this.amount_lib);
        }
        data.append('isDBM', tab == 1 ? 0 : 1);
        data.append('PPMPTypeID', (parseInt(tab) + 1));
        data.append('itemList', list);
        data.append('itemListToDelete', JSON.stringify(this.itemListToDelete_dbm.concat(this.itemListToDelete_non_dbm, this.itemListToDelete_lib)));
                    this.axiosCall(
                  '/PPMP/new/save',
                  'POST',
                  data
              )
              .then((res) => {
                  this.saveLoading = false;
                  this.dialogActionConfirm = false;
                  if(res.data.status) {
                  this.fadeAwayMessage.show = true;
                  this.fadeAwayMessage.type = 'success';
                  this.fadeAwayMessage.message = 'PPMP Successfully Saved!';
                  this.fadeAwayMessage.header = 'System Message';
                  this.getPPMPItems();
                  this.itemListToDelete = [];
                  this.itemListToDelete_dbm = [];
                  this.itemListToDelete_non_dbm = [];
                  this.itemListToDelete_lib = [];

                  }else {
                  this.fadeAwayMessage.show = true;
                  this.fadeAwayMessage.type = 'error';
                  this.fadeAwayMessage.message = res.data.message;
                  this.fadeAwayMessage.header = 'System Message';
                  }
              })
        });
      }
    },
    getPPMPItems(){
      let data = new FormData();
        data.append('BudgetID', this.data.BudgetID);
            this.axiosCall(
              '/getAllPPMPByBudgetID',
              'POST',
                data
          )
          .then((res) => {
              this.itemList_dbm = res.data.data.dbm_items
              this.itemList_non_dbm = res.data.data.non_dbm_items
              this.itemList_lib = res.data.data.lib_items
              
              this.dbm_data = res.data.data.dbm
              this.non_dbm_data = res.data.data.non_dbm
              this.lib_data = res.data.data.lib

              let amount1 = 0;
                this.itemList_dbm.forEach(item => {
                  amount1 += parseFloat(item.Qty) * parseFloat(item.UnitCost);
                });
              this.amount_dbm = amount1.toFixed(2);
              let amount2 = 0;
                this.itemList_non_dbm.forEach(item => {
                  amount2 += parseFloat(item.Qty) * parseFloat(item.UnitCost);
                });
              this.amount_non_dbm = amount2.toFixed(2);
              let amount3 = 0;
                this.itemList_lib.forEach(item => {
                  amount3 += parseFloat(item.Qty) * parseFloat(item.UnitCost);
                });
              this.amount_lib = amount3.toFixed(2);

              if(this.tab == 0){
                this.itemList = this.itemList_dbm;
                if(this.dbm_data){
                  this.formData.PPMPDescription = this.dbm_data.PPMPDescription;
                  this.formData.approvalID = this.dbm_data.RecommendingApprovalID;
                  this.currentData = this.dbm_data;
                }
              }
              else if(this.tab == 1){
                this.itemList = this.itemList_non_dbm;
                if(this.non_dbm_data){
                  this.formData.PPMPDescription = this.non_dbm_data.PPMPDescription;
                  this.formData.approvalID = this.non_dbm_data.RecommendingApprovalID;
                  this.currentData = this.non_dbm_data;
                }
              }
              else if(this.tab == 2){
                this.itemList = this.itemList_lib;
                if(this.lib_data){
                  this.formData.PPMPDescription = this.lib_data.PPMPDescription;
                  this.formData.approvalID = this.lib_data.RecommendingApprovalID;
                  this.currentData = this.lib_data;
                }
              }
          })
    },
    publish(){
      this.action = "publish";
      this.dialogActionConfirm = true;
    },
    confirmPublish(){
      if (this.$refs.Formref3.validate()) {
      this.confirmSave();
      this.publishLoading = true;
      let data = new FormData();
        data.append('BudgetID', this.data.BudgetID);
        data.append('Amount', (parseFloat(this.amount_dbm) + parseFloat(this.amount_non_dbm) + parseFloat(this.amount_lib)).toFixed(2));
            this.axiosCall(
              '/ppmp/publishNew',
              'POST',
                data
          )
          .then((res) => {
              this.publishLoading = false;
              this.dialogActionConfirm = false;
              if(res.data.status) {
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = 'success';
              this.fadeAwayMessage.message = 'PPMP Successfully Published!';
              this.fadeAwayMessage.header = 'System Message';
              this.getPPMPItems();
              this.itemListToDelete = [];
              this.itemListToDelete_dbm = [];
              this.itemListToDelete_non_dbm = [];
              this.itemListToDelete_lib = [];

              }else {
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = 'error';
              this.fadeAwayMessage.message = res.data.message;
              this.fadeAwayMessage.header = 'System Message';
              }
          })
      }
    },
    getFilteredMonths(startDate, endDate) {
      this.filteredMonths = [];
      var start      = startDate.split('-');
      var end        = endDate.split('-');
      var startYear  = parseInt(start[0]);
      var endYear    = parseInt(end[0]);

        for(var i = startYear; i <= endYear; i++) {
          var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
          var startMon = i === startYear ? parseInt(start[1])-1 : 0;
          for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
            var month = j+1;
            var displayMonth = month < 10 ? '0'+month : month;
            this.filteredMonths.push({month: this.months[displayMonth - 1], description: this.months[displayMonth - 1] + ' ' + i})
          }
        }
    },
    isNegative(num) {
      if (Math.sign(num) === -1) {
        return true;
      }

      return false;
    }
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.ppmpModal {
  overflow-x: hidden !important;
}
.dt-container {
    border-radius: 15px !important;
}
.dt-container thead th:first-child {
border-radius: 15px 0 0 0 !important  ;
}
.dt-container thead th:last-child {
border-radius: 0 15px 0 0 !important;
}
</style>